<template>
    <div>
        <DataTable
            :value="orgsTags"
            :paginator="true"
            class="p-datatable-customers p-datatable-striped"
            :class="{ showFilters: showFilters }"
            :rowsPerPageOptions="rowsPerPageOptions"
            :rows="20"
            :scrollable="true"
            scroll-height="calc(100vh - 350px)"
            :totalRecords="totalRecords"
            dataKey="id"
            selectionMode="single"
            :filters="filters"
            stripedRows
            @row-dblclick='rowDblClickHandler'
        >
            <!-- :currentPageReportTemplate="`Показана с {first} по {last} всего ${totalRecords}`" paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown" -->
            <!-- :lazy="true"
            @page="paginationChange($event)"
            @sort="onSort($event)" -->
            <template #header>
                <div class="table-header p-d-flex p-jc-md-between p-flex-wrap">
                    <div class="p-col-7 p-p-0 p-d-flex p-ai-center p-mb-2">
                        <h5 class="p-d-inline p-mb-0 p-pt-0">Теги</h5>
                    </div>
                    <div class="p-col-6 p-p-0 p-d-flex p-ai-center p-mt-0"></div>
                    <div class="p-col-6 p-p-0 p-d-flex p-ai-center">
                        <div class="p-inputgroup p-p-0 p-d-flex">
                            <span class="p-float-label">
                                <InputText type="text" v-model="filters['global']" placeholder="Поиск" />
                            </span>
                            <span class="p-inputgroup-addon">
                                <i class="pi pi-search"></i>
                            </span>
                        </div>
                        <div v-if='canEdit' class="p-p-0 p-new-btn">
                            <Button @click="openAddCollectionDialog('Добавить')" class="p-button p-ml-3"> Добавить </Button>
                        </div>
                    </div>
                </div>
            </template>
            <template #empty> Тегов не найдено.</template>
            <template #loading> Загрузка списка тегов. Пожалуйста, подождите. </template>
            <Column header="Наименование" header-class="tag-name-header table-column" body-class="tag-name-body" body-style="max-height: 44px;" field="name" :sortable="true">
                <template #body="slotProps">
                    <div class="p-text-nowrap p-text-truncate">{{ slotProps.data.name }}</div>
                </template>
<!--                <template #filter>-->
<!--                    <InputText v-show="showFilters" type="text" v-model="filters['name']" class="p-column-filter" placeholder="Наименование" />-->
<!--                </template>-->
            </Column>
<!--            <Column header="Статус" header-class="tag-status-header table-column" body-class="tag-status-body" body-style="max-height: 44px;" field="status" :sortable="true">-->
<!--                <template #body="slotProps">-->
<!--                    <div class="status" :class="{ activeStatus: slotProps.data.status == 'активная', noActiveStatus: slotProps.data.status == 'неактивная' }">{{ slotProps.data.status }}</div>-->
<!--                </template>-->
<!--                <template #filter>-->
<!--                    <Dropdown v-show="showFilters" v-model="filters['status']" :options="statuses" placeholder="Статус" class="p-column-filter" :showClear="true">-->
<!--                        <template #option="slotProps">-->
<!--                            <span :class="'customer-badge status-' + slotProps.option">{{ slotProps.option }}</span>-->
<!--                        </template>-->
<!--                    </Dropdown>-->
<!--                </template>-->
<!--            </Column>-->
            <Column header="" body-style="position: relative; width: 52px; text-align: center;max-height: 44px;" header-style="width: 52px;">
                <template v-if='accessibleItems(listMenuItems).length' #body="slotProps">
                    <button
                        class="p-panel-header-icon p-link p-mr-2"
                        @click.stop="showTagMenu($event, slotProps.data.id, slotProps.data.active)"
                    >
                        <span class="pi pi-ellipsis-h"></span>
                    </button>
                    <Menu
                        id="list_menu"
                        class="redLastListElement"
                        :ref="`listMenu${slotProps.data.id}`"
                        :model="accessibleItems(listMenuItems)"
                        :popup="true"
                    />
                </template>

<!--                <template #header>-->
<!--                    <Button icon="pi pi-filter " :class="filterClasses" class="p-button-rounded p-button-outlined filter-btn" @click="showFilters = !showFilters" />-->
<!--                </template>-->
            </Column>
            <template #paginatorLeft>
                <div class="p-col-6"></div>
            </template>
        </DataTable>
        <Dialog
            :visible.sync="deleteTagDialogShow"
            position="center"
            :modal="true"
            :closeOnEscape="true"
            :closable="true"
            style="width: 400px"
            class="delDialog"
        >
            <template #header>
                <h3 class="p-mb-0 p-pl-2 customTitle">Удалить тег</h3>
            </template>
            <div class="p-dialog-content p-ai-center p-d-flex p-pl-2">
                <span>Подтвердите, пожалуйста, что вы удаляете тег. Восстановить его не получится.</span>
            </div>
            <template #footer>
                <div class="p-d-flex p-jc-start">
                    <Button
                        class="p-button p-button-danger"
                        @click="removeTag(currentTag)"
                    >
                        <span class="p-button-label">Удалить</span>
                    </Button>
                    <Button class="p-button p-button-outlined p-button-secondary" type="button" @click="hideDeletePopUp">
                        <span class="p-button-label">Отменить</span>
                    </Button>
                </div>
            </template>
        </Dialog>

        <Dialog :visible.sync="addCollectionElementDialog" position="center" :modal="true" :closeOnEscape="true" :closable="true" style="width: 500px" class="customDialog">
            <template #header>
                <h3 class="p-mb-0 customTitle p-px-2">{{ dialogMode === 'Добавить' ? 'Добавить тег' : 'Редактировать тег' }}</h3>
            </template>

            <div class="p-d-flex p-flex-column p-field p-col-12 p-py-0 p-mb-0 p-px-2">
                <label for="newOrgsTagName">Наименование*</label>
                <InputText id="system" type="text" v-model="v$.newCollectionElementName.$model"
                           placeholder="Введите наименование тега"> </InputText>
                <small v-show="submitted" v-for="error in showErrors(v$.newCollectionElementName)" :key="error.$uid" class="p-error">
                    <small>{{ error.$message }}</small><br>
                </small>
            </div>

            <template #footer>
                <div class="p-d-flex p-jc-start">
                    <Button class="p-button" v-if="dialogMode == 'Добавить'" type="button" @click="createTag" :disabled="newCollectionElementName.length < 1"> <span class="p-button-label">Добавить </span> <span class="p-ink"></span> </Button>
                    <Button class="p-button" v-else type="button" @click="patchTag(currentTag)" :disabled="newCollectionElementName.length < 1"> <span class="p-button-label">Изменить </span> <span class="p-ink"></span> </Button>

                    <Button class="p-button p-button-outlined" @click="closeDialog">
                        <span class="p-button-label black">Отменить</span>
                    </Button>
                </div>
            </template>
        </Dialog>
    </div>
</template>

<script>
import {directoryTagList, patchDirectoryTagList, tagsReferenceList} from '@/api/organization/orgsTags';
import {mapGetters} from 'vuex';
import {requestToastHandler} from '@/main/mixins';
import {useVuelidate} from '@vuelidate/core';
import { helpers, minLength, required } from '@vuelidate/validators';
import { USER_PERMISSIONS_MAP } from '@/constants/common';

const { organizationWriteAll } = USER_PERMISSIONS_MAP

export default {
    name: 'tags',
    props: {
        loading: {
            type: Boolean,
            require: false,
        },
    },
    emits: ['loadingChange'],
    mixins: [requestToastHandler],
    setup: () => ({ v$: useVuelidate() }),
    data() {
        return {
            filters: {},
            showFilters: false,
            currentPage: 1,
            totalRecords: 0,
            deleteTagDialogShow: false,
            dialogMode: '',
            selectedYear: new Date().getFullYear(),
            orgsTags: [],
            filteredYears: [],
            currentTagId: null,
            currentTagStatus: null,
            currentTag: null,
            addCollectionElementDialog: false,
            newCollectionElementName: '',
            addCollectionElementDialogLoading: false,
            listMenuItems: [
                {
                    label: 'Редактировать',
                    icon: 'pi pi-pencil',
                    command: () => {
                        this.openAddCollectionDialog('Редактировать');
                    },
                    permissionAccess: [organizationWriteAll]
                },
                {
                    label: 'Удалить',
                    icon: 'pi pi-trash',
                    command: () => {
                        // this.showDeletePopUp();
                        this.$root.$emit('showAcceptDeleteDialog', {
                            acceptAction: () => this.removeTag(this.currentTag),
                            cancelAction: this.hideDeletePopUp
                        })
                    },
                    class: 'remove-row',
                    permissionAccess: [organizationWriteAll]
                },
            ],
            statuses: ['активная', 'неактивная'],
            submitted: false,
        };
    },
    validations() {
        const tagNameIsUnique = (val) => {
            return !this.orgsTags.map(i => i.name).includes(val)
        }
        return {
            newCollectionElementName: {
                required: {
                    ...required,
                    $message: 'Поле обязательно к заполнению'
                },
                minLength: {
                    ...minLength(1),
                    $message: 'Значение не должно быть короче 1 символа'
                },
                isUnique: helpers.withMessage('Такой тег уже есть', tagNameIsUnique)
            }
        }
    },
    methods: {
        rowDblClickHandler(event) {
            const { data: { id, active, name } } = event
            this.currentTagId = id;
            this.currentTagStatus = active;
            this.newCollectionElementName = name;
            this.openAddCollectionDialog('Редактировать')
        },
        openAddCollectionDialog(mode) {
            if (!this.canEdit) return
            this.submitted = false;
            this.dialogMode = mode;
            console.log('mode', mode);
            if (mode == 'Добавить') this.newCollectionElementName = '';
            this.addCollectionElementDialog = true;
        },
        showDeletePopUp() {
            this.deleteTagDialogShow = true;
        },
        hideDeletePopUp() {
            this.deleteTagDialogShow = false;
            this.currentTagId = null;
            this.currentTag = null;
        },
        closeDialog() {
            this.dialogMode = '';
            this.newCollectionElementName = '';
            this.currentTagId = null;
            this.currentTagStatus = null;
            this.currentTag = null;
            this.addCollectionElementDialog = false;
        },
        showTagMenu(event, TagId, active) {
            if (TagId !== this.currentTagId) {
                Object.keys(this.$refs).map((key) => {
                    this.$refs[key] && this.$refs[key].hide(event);
                });
            }
            this.currentTagId = TagId;
            this.currentTagStatus = active;
            this.currentTag = this.orgsTags.find((tags) => tags.id === TagId);
            this.newCollectionElementName = this.currentTag.name;
            this.$refs[`listMenu${TagId}`].toggle(event);
        },
        showErrors(data) {
            return data.$errors.length ? data.$errors : data.$silentErrors;
        },
        async getData() {
            this.$emit('loadingChange', true);
            try {
                const years = await tagsReferenceList({});
                this.years = years.map((year) => year.id);
                this.filteredYears = [...this.years];

                const result = await directoryTagList(this.selectedYear);
                this.orgsTags = result.map((tag) => {
                    return {
                        id: Date.now() + Math.random(),
                        name: tag,
                    };
                });
            } catch (error) {
                this.$requestError(error.message);
            }

            this.$emit('loadingChange', false);
        },

        async createTag() {
            this.submitted = true;

            if (!this.v$.$invalid) {
                this.addCollectionElementDialog = false;
                const newOrgsTags = this.orgsTags.map((tagObj) => {
                    return tagObj.name;
                });
                newOrgsTags.push(this.newCollectionElementName);
                this.closeDialog();

                try {
                    this.$emit('loadingChange', true);
                    await this.patchDirectoryList(newOrgsTags);
                    this.$emit('loadingChange', false);
                } catch (error) {
                    this.$requestError(error.message);
                }
            }
        },
        async patchTag(newOrgsTag) {
            this.submitted = true;

            if (!this.v$.$invalid) {
                this.$emit('loadingChange', true);
                try {
                    this.addCollectionElementDialog = false;
                    const newOrgsTags = this.orgsTags.map((tagObj) => {
                        return tagObj.id === newOrgsTag.id ? this.newCollectionElementName : tagObj.name;
                    });
                    await this.patchDirectoryList(newOrgsTags);
                    this.closeDialog();
                    this.$emit('loadingChange', false);
                } catch (error) {
                    this.$requestError(error.message);
                    this.$emit('loadingChange', false);
                }
            }
        },
        async removeTag(tag) {
            this.addCollectionElementDialog = false;
            // const index = this.orgsTags.findIndex((orgsTag) => orgsTag === tag);
            try {
                this.$emit('loadingChange', true);
                const newOrgsTags = this.orgsTags
                    .filter((tagObj) => tagObj.id !== tag.id)
                    .map((tagObj) => {
                        return tagObj.name;
                    });
                await this.patchDirectoryList(newOrgsTags);
                this.$emit('loadingChange', false);
                // this.$toast.add({
                //     severity: 'success',
                //     summary: 'Тег успешно удален',
                //     life: '3200',
                // });
                this.hideDeletePopUp();
            } catch (error) {
                this.$requestError(error.message);
                this.$emit('loadingChange', false);
            }
        },
        async patchDirectoryList(newOrgsTags) {
            const dataToServer = {
                data: {
                    type: 'organization-tags',
                    id: String(this.selectedYear),
                    attributes: {
                        year: +this.selectedYear,
                        tags: newOrgsTags,
                    },
                },
            };

            try {
                await patchDirectoryTagList(this.selectedYear, dataToServer);

                this.orgsTags = newOrgsTags.map((tag) => {
                    return {
                        id: Date.now() + Math.random(),
                        name: tag,
                        status: 'активная',
                    };
                });
            } catch (error) {
                this.$requestError(error.message);
            }
        },

        sortArray(array) {
            return array.sort((a, b) => {
                if (a.name > b.name) return 1;
                if (a.name < b.name) return -1;
                return 0;
            });
        },
        searchDate(event) {
            setTimeout(() => {
                if (!event.query.trim().length) {
                    this.filteredYears = [...this.years];
                } else {
                    this.filteredYears = this.years.filter((year) => year.toString().indexOf(event.query.toString()) >= 0);
                }
            }, 250);
        },
    },
    computed: {
        ...mapGetters(['rowsPerPageOptions']),
        ...mapGetters('auth', [
            'userPermissionsObject',
            'accessibleItems'
        ]),
        filterClasses() {
            return this.showFilters ? 'mainBackground' : '';
        },
        canEdit() {
            return [organizationWriteAll].some(p => this.userPermissionsObject[p])
        }
    },

    async mounted() {
        await this.getData();
    },
    watch: {

    }
};
</script>

<style lang='scss' scoped>
.autocompleteWidth {
    width: 100px;
    ::v-deep {
        .p-button {
            color: #8794a3;
            background: white !important;
            border: 1px solid #eaeaea;
            border-left: none;
        }
        input {
            border-right: none;
        }
    }
}
.status {
    border-radius: 4px;
    padding: 4px;
    max-width: 64px;
    color: #272727;
    font-size: 13px;
    line-height: 16px;
    font-style: normal;
    font-weight: normal;
    // margin: 0 auto;
}
.activeStatus {
    background-color: #a1e6c3;
}
.noActiveStatus {
    background-color: #d7d7d7;
    max-width: 78px;
}

.customTitle {
    font-size: 17px;
}
::v-deep .p-datatable-header {
    padding-top: 0px;
    border-top: none;
    padding-left: 0;
    .p-inputtext:enabled:hover {
        border-color: #eaeaea;
    }
    .p-autocomplete .p-component .p-inputwrapper .p-autocomplete-dd .p-inputwrapper-filled {
        .p-button {
            background: white;
        }
    }
}
::v-deep .p-datatable {
    .p-datatable-tbody > tr > td {
        border: none;
    }
}
// ::v-deep .redLastListElement {
//     top: 10px !important;
//     right: 10px !important;
//     left: -9% !important;
//     li:last-child {
//         span {
//             color: red !important;
//         }
//     }
// }
::v-deep .customDialog {
    width: 480px;
}
::v-deep .p-inputgroup-addon {
    background: inherit;
    border-left: none;
}
::v-deep .p-inputgroup {
    .p-float-label {
        input {
            border-right: none;
        }
    }
}

::v-deep .p-datatable-thead {
    position: relative;

    // .mainBackground {
    //     background-color: #388e3c;
    //     color: white;
    //     outline: none;
    //     border: none;
    // }

    tr:last-child {
        th:last-child {
            text-align: right;
        }
    }
    .p-filter-column {
        padding: 0;
        background-color: #fff !important;
        input {
            min-width: 98%;
            max-width: 98%;
            margin: 5px;
        }
        .p-dropdown-clearable {
            max-width: 98%;
        }
    }
}
.p-datatable {
    &-customers {
        ::v-deep {
            .table-column {
                padding-right: 3.411vw;
            }

            .tag-name {
                &-header {
                    width: 57.1875vw;
                }
                &-body {
                    width: 57.1875vw;
                }
            }

            .tag-status {
                &-header {
                    width: 16.484375vw;
                }
                &-body {
                    width: 16.484375vw;
                    text-align: left;
                }
            }

            .p-filter-column {
                padding: 4px 0 4px 1rem;
                background-color: #fff !important;
                input {
                    min-width: 98%;
                    max-width: 98%;
                }
            }

            .p-datatable-header {
                padding-left: 0;
                padding-top: 0;
                border: unset;
            }
            .filter-btn {
                width: 24px;
                height: 24px;
                background: white;
            }
            tr {
                .redLastListElement {
                    position: fixed !important;
                    display: block !important;
                    z-index: 1042;
                    left: unset !important;
                    transform: translateX(-100%);
                    li:last-child {
                        span {
                            color: red !important;
                        }
                    }
                }
            }
        }
    }
}
.p-new-btn {
    height: 100%;

    .p-button {
        height: 100%;
        align-items: flex-end;
    }
}
::v-deep .p-datatable .p-datatable-tbody > tr > td,
::v-deep .p-datatable .p-datatable-thead > tr > th {
    padding: 0.7rem 1rem;
}
::v-deep .p-datatable-thead {
    .p-filter-column {
        padding: 0 !important;
    }
}
::v-deep .p-datatable-scrollable-header {
    overflow: visible;
}
</style>
